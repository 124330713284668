<template>
  <!-- NAME[epic=admin] 客户数据维护 -->
  <div class="orderTest-container">
    <el-form ref="form" :model="form" inline>
      <el-form-item>
        <el-select
          v-model="province"
          placeholder="省"
          style="width: 120px"
          clearable
          @change="handlerprovince"
        >
          <el-option
            v-for="listd in provincelist"
            :key="listd.id"
            :label="listd.title"
            :value="listd.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="city"
          placeholder="市"
          clearable
          style="width: 120px; margin: 0px 10px"
          @change="handlercity"
        >
          <el-option
            v-for="list1 in citylist"
            :key="list1.id"
            :label="list1.title"
            :value="list1.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="area"
          placeholder="县"
          clearable
          style="width: 120px"
          @change="handlerarea"
        >
          <el-option
            v-for="list in arealist"
            :key="list.id"
            :label="list.title"
            :value="list.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="keyword">
        <el-input v-model="form.keyword" placeholder="企业id或名称" />
      </el-form-item>
      <el-form-item prop="name">
        <el-input v-model="form.name" placeholder="联系人或手机号" />
      </el-form-item>
      <el-form-item prop="is_close">
        <el-select
          v-model="form.is_close"
          placeholder="状态"
          style="width: 160px"
        >
          <el-option
            v-for="lists in status"
            :key="lists.id"
            :label="lists.name"
            :value="lists.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="area_id">
        <el-select
          v-model="form.vest_in"
          placeholder="归属"
          style="width: 160px"
        >
          <el-option
            v-for="list in Attribution"
            :key="list.id"
            :label="list.truename"
            :value="list.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="from">
        <el-select v-model="form.from" placeholder="来源" style="width: 160px">
          <el-option
            v-for="list in from"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          type="warning"
          @click="
            () => {
              $refs['form'].resetFields()
              province = ''
              city = ''
              area = ''
            }
          "
        >
          重 置
        </el-button>
      </el-form-item>
    </el-form>
    <el-table stripe :data="tableData">
      <el-table-column
        v-for="(list, index) in colemd"
        :key="index"
        :label="list.label"
        :align="list.align"
        :prop="list.prop"
        width=""
      ></el-table-column>
      <el-table-column label="操作" align="center" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleradmin(row)">基础资料</el-button>
          <el-button type="text" @click="handlerbasis(row)">单据维护</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="pageSizes"
        :page-size="form.pageSize"
        :layout="layout"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <basis ref="basis"></basis>
    <basic-information ref="basic"></basic-information>
  </div>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import pagination from '@/mixins/pagination'
  // import Template from '../../systemManagement/adminAccount/components/template.vue'
  export default {
    name: 'DataExport',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      Basis: () => import('./components/basis.vue'),
      basicInformation: () => import('@/baseComponents/BasicInformation.vue'),
    },
    mixins: [pagination],
    data() {
      return {
        url: {
          area: '/baseAdmin/common/common-area',
          save: '/superAdmin/user-data/index',
          Attri: 'superAdmin/user/index',
        },
        form: {
          // pageSize: 10,
          // pageNo: 1,
          keyword: '', //企业id或名称
          name: '', //姓名或手机号
          vest_in: '', //归属人id
          is_close: '', //期限状态1已封存3正常4即将过期5已过期
          from: '', //注册来源  1app注册2PC注册3工作站4转介绍
          area_id: '', //地区ID
        },
        province: '',
        city: '',
        area: '',
        tableData: [],
        from: [
          {
            id: 1,
            name: 'app注册',
          },
          {
            id: 2,
            name: 'PC注册',
          },
          {
            id: 3,
            name: '工作站',
          },
          {
            id: 4,
            name: '转介绍',
          },
        ],
        status: [
          {
            id: 1,
            name: '已封存',
          },
          {
            id: 3,
            name: '正常',
          },
          {
            id: 4,
            name: '即将过期',
          },
          {
            id: 5,
            name: '已过期',
          },
        ],
        provincelist: [],
        citylist: [],
        arealist: [],
        colemd: [
          {
            label: '客户ID',
            align: 'center',
            prop: 'id',
            width: '',
          },
          {
            label: '企业名称',
            align: 'center',
            prop: 'dealer_name',
            width: '',
          },
          {
            label: '联系人',
            align: 'center',
            prop: 'boss',
            width: '',
          },
          {
            label: '手机',
            align: 'center',
            prop: 'mobile',
            width: '',
          },
          {
            label: '正式账号数',
            align: 'center',
            prop: 'user_real_count',
            width: '',
          },
          {
            label: '测试账号数',
            align: 'center',
            prop: 'user_test_count',
            width: '',
          },
          {
            label: '仓库',
            align: 'center',
            prop: 'depot2_count',
            width: '',
          },
          {
            label: '车辆',
            align: 'center',
            prop: 'depot3_count',
            width: '',
          },
          {
            label: '客户',
            align: 'center',
            prop: 'cust_count',
            width: '',
          },
          {
            label: '商品',
            align: 'center',
            prop: 'goods_count',
            width: '',
          },
          {
            label: '归属',
            align: 'center',
            prop: 'vest_name',
            width: '',
          },
        ],
        total: 0,
        Attribution: [],
      }
    },
    computed: {},
    watch: {
      'form.area_id'(val) {
        console.log(val, '这个是什么')
      },
    },
    created() {},
    mounted() {
      this.handlerSheng()
      // this.handlerInquire()
      this.handlerAttribution()
    },
    methods: {
      //归属地获取
      handlerAttribution() {
        postAction(this.url.Attri, { pageSize: -1 })
          .then((res) => {
            console.log(res)
            this.Attribution = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleradmin(row) {
        this.$refs.basic.admindata = true
        this.$refs.basic.headlerData(row.id)
      },
      handlerbasis(row) {
        this.$refs.basis.basisshow = true
        this.$refs.basis.headlerData(row.id)
      },
      //获取省
      handlerSheng() {
        this.handlerssq('').then((res) => {
          console.log(res, '省')
          this.provincelist = res
        })
      },
      //获取市
      handlerprovince(e) {
        console.log(e, '市')
        this.form.area_id = e
        this.city = ''
        this.area = ''
        this.handlerssq(e).then((res) => {
          console.log(res, '市')
          this.citylist = res
        })
      },
      //获取区
      handlercity(e) {
        console.log(e, '区')
        this.form.area_id = e
        this.area = ''
        this.handlerssq(e).then((res) => {
          console.log(res, '区')
          this.arealist = res
        })
      },
      //选取县
      handlerarea(e) {
        this.form.area_id = e
        console.log(this.form.area_id, 'lili')
      },
      //省市区
      handlerssq(ID) {
        let promise = new Promise((Result, reject) => {
          postAction(this.url.area, { parent_id: ID })
            .then((res) => {
              Result(res.data)
            })
            .catch((err) => {
              console.log(err, '')
              reject(err)
            })
        })
        return promise
      },
      //查询接口
      handlerInquire() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.save, this.form)
          .then((res) => {
            console.log('res', res)
            this.tableData = res.data
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log('err', err)
          })
      },
      // handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`)
      //   this.form.pageSize = val
      //   this.handlerlist()
      // },
      // handleCurrentChange(val) {
      //   console.log(`当前页: ${val}`)
      //   this.form.pageNo = val
      //   this.handlerlist()
      // },
    },
  }
</script>
<style lang="scss" scoped></style>
